@import url("https://fonts.googleapis.com/css2?family=Tilt+Neon&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Poppins:wght@200;300;400;600&family=Tilt+Neon&display=swap");

:root {
  --primary-color: #600040;
  --background-color: #8f00ffff;
  --text-color: #04112a;
  --font-size: 16px;
  --small-text-color: #999;
}

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  cursor: none;
}
@media only screen and (max-width: 768px) {
  * {
    cursor: auto;
  }
}

body {
  scroll-behavior: smooth;
  font-family: "Poppins", sans-serif;
  overflow-x: hidden;
  background: rgb(15, 1, 73);
    background: linear-gradient(
      90deg,
      rgba(15, 1, 73, 1) 2%,
      rgba(40, 0, 66, 1) 100%
    );
}
html{
  scrollbar-width: auto;
}
.cursor {
  z-index: 21;
  background: rgba(149, 0, 255, 0.213);
  width: 40px;
  height: 40px;
  border-radius: 50%;
  pointer-events: none;
  box-shadow: 0 0 50px rgb(149, 0, 255), 0 0 100px rgb(116, 250, 245),
    0 0 180px rgb(0, 140, 255), 0 0 380px rgb(0, 140, 255);
  animation: colors 10s infinite;
  // transition: .1s ease-in-out;
  position: fixed;
  display: flex;
  align-items: center;
  justify-content: center;
  display: none;
}

@keyframes colors {
  0% {
    filter: hue-rotate(0deg);
    opacity: 0;
  }
  10% {
    opacity: 1;
  }

  100% {
    filter: hue-rotate(360deg);
  }
}

.cursor:before {
  content: "";
  position: absolute;
  background: #fff9f9cb;
  width: 60px;
  height: 60px;
  opacity: 0.2;
  transform: translate(-16%, -16%);
  border-radius: 50%;
}

// scrollbar

.container-title {
  margin-bottom: 100px;
  position: relative;
  color: var(--primary-color);
  h3 {
    font-family: "Tilt Neon", cursive;
  }
}

::-webkit-scrollbar-track {
  -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.1);
  background-color: #e30097a2;
  border-radius: 10px;
}

::-webkit-scrollbar {
  width: 10px;
  background-color: #05f5fd00;
}
::-webkit-scrollbar-thumb {
  border-radius: 6px;
  background-color: #fff;
  background-image: -webkit-gradient(
    linear,
    40% 0%,
    75% 84%,
    from(#32013a),
    to((#32013a)),
    color-stop(0.6, #9b00fc)
  );
}

// Header Section

