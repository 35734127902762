

.product__item {
  overflow: hidden;
  width: 100% !important;
  height: 400px !important;
  position: relative;
  overflow: hidden;
  transform: rotateX(51deg) rotateZ(43deg) scale(0.7);
  transform-style: preserve-3d;
  border-radius: 32px;
  box-shadow: 1px 1px 0 1px #fbf9fb, -1px 0 28px 0 rgba(34, 33, 81, 0.01),
    28px 28px 28px 0 rgba(34, 33, 81, 0.25);
  transition: 0.4s ease-in-out transform, 0.4s ease-in-out box-shadow;

  &:hover {
    z-index: 1;
    transform: translate3d(0px, -16px, 0px) rotateX(0deg) rotateZ(0deg);
    box-shadow: 0px 0px 0 1px #3e0178, 0px 0 0 20px rgba(204, 4, 245, 0.01),
      1px 1px 28px -10px rgba(155, 32, 248, 0.15);

  }
  .product__img {
    width: 50%;
    transform: translate3d(190deg);
    img {
      border-end-end-radius: 32px !important;
      width: 100%;
      transition: 9s;
      transform: transition translateY(0px);
    }

    &:hover img {
      transform: translateY(calc(-100% + 400px));
      cursor: help;
    }
  }

  .dark__theme {
    border-end-end-radius: 32px !important;
    position: absolute;
    z-index: -1;
    top: 0;
    right: 0;
    bottom: 0;
    left: 50%;
    background: #a200ff03;
    backdrop-filter: blur(70px);
    color: #fff !important;
  }
  .info__btn {
    color: rgba(255, 255, 255, 0.6);
    border: 1px solid rgba(255, 255, 255, 0.3);
    position: absolute;
    bottom: 20px;
    right: 20px;
    &:hover {
      color: #fff;
      border: 1px solid rgba(255, 255, 255, 0.8);
    }
  }
}
