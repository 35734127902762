.work-section {
    overflow: hidden !important;
    color: #fff;
    width: 100%;
    // height: 100vh;
    padding-top: 100px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
  
    .container-title {
      margin: 0;
      bottom: 0;
    }
  }