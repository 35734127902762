.about__item {
  width: 100%;
  height: 200px;

  margin: 2px 5px;
  border: 1px solid rgba(255, 255, 255, 0.2);
  border-radius: 10px;

  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  position: relative ;

  .about__img {
    position: absolute ;
    top: 6%;
    img {
      width: 100px;
      //   height: 100px;
      //   background-position: center;
      //   background-repeat: no-repeat;
      //   background-size: cover;
    }
  }
  .info {
    font-size: 1rem;
    padding-top: 10px;
    position: absolute;
    bottom: 0;
  }
  @media only screen and (max-width: 375px){
    .info{
      font-size: .8rem;

    }
  }

}


.slick-slider .slick-dots li button:before {
  color: #9233ff !important;
  border-radius: 50%;
  margin-top: 10px;
  background: transparent;
  box-shadow: 0 0 20px rgb(186, 5, 227);
}
