.loading-section {
  width: 100%;
  height: 100vh;
  background: rgba(54, 54, 54, 0.813);
  display: flex;
  align-items: center;
  justify-content: center;
}

.line-img {
  /* border-radius: 50%; */
  animation: colors 10s infinite;
}

