.about-section {
  overflow: hidden;
  padding: 10px 0;
  font-size: 1.2rem;
  width: 100%;
  height: 100vh;
  background: rgb(15, 1, 73);
  background: linear-gradient(
    290deg,
    rgba(15, 1, 73, 1) 2%,
    rgba(40, 0, 66, 1) 100%
  );
  color: #fff;
  position: relative;

  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  cursor: none;

  .container-about {
    display: flex !important;
    align-items: center !important;
    justify-content: center !important;
    background: var(--text-color);
    border-radius: 20px;
    box-shadow: 0 0 15px rgba(149, 0, 255, 0.317),
      0 0 30px rgba(71, 1, 88, 0.876), 0 0 90px rgba(71, 1, 88, 0.709);
    z-index: 3;
    .main__about {
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      overflow: hidden;
      height: 100%;
      margin: 5px 0;
      padding: 10px 0;

      .work__together {
        position: relative;
        position: absolute;
        transform: translateY(0px) !important;
        transition: 0.5s ease-in-out;
        font-weight: 600;
        margin-left: 5px;
        a {
          cursor: alias;
          color: var(--primary-color);
          text-decoration: none;
        }

        &::before {
          position: absolute;
          bottom: 1px;
          left: -1px;
          content: "";
          width: 0%;
          height: 0px;
          background: rgba(149, 0, 255, 0.317);
          box-shadow: 0 0 10px rgba(149, 0, 255, 0.317),
            0 0 20px rgba(71, 1, 88, 0.876), 0 0 30px rgba(71, 1, 88, 0.709);
          transform: scaleX(0);
          transition: 0.3s ease-in-out;
        }
        &:hover {
          &::before {
            width: 100%;
            height: 2px;
            transform: scaleX(1);
          }
        }
      }
      .work__together:hover {
        transform: translateY(-3px) !important;
      }
    }
  }
  .container-slider {
    position: relative;
    z-index: 2;
  }
}

.container-title h3 {
  letter-spacing: 0.15em;
}

@media only screen and (max-width: 768px) {
  .about-section {
    font-size: 0.9rem;
  }

  .main__about-experience {
    align-items: center;
    ul {
      li {
        margin: 10px 0 !important;
      }
    }
  }
}

@media only screen and (max-width: 425px) {
  .about-section {
    font-size: 0.75rem;
  }

  .main__about-experience {
    align-items: center;
    ul {
      li {
        margin: 7px 0 !important;
      }
    }
  }
}

@media only screen and (max-width: 210px) {
  .about-section {
    font-size: 0.6rem;
  }
}
