.sticky__header {
    height: 80px !important;
    line-height: 80px;
    position: fixed !important;
    top: 3px;
    left: 0;
    z-index: 99;
    transition: 0.5s;
    animation: stickyheader 0.5s ease-in-out;
  
    .header {
      box-shadow: 3px 3px 8px -4px #ddd;
    }
  
    a {
      padding: 0 !important;
      display: flex;
      align-items: center;
      justify-content: center;
      width: 80px !important;
      height: 40px;
      animation: stickylinks 1s ease-in-out;
    }
    @keyframes stickyheader {
      from {
        transform: translateY(-100%);
      }
      to {
        transform: translateY(0%);
      }
    }
    @keyframes stickylinks {
      from {
        transform: translateY(-100%);
      }
      to {
        transform: translateY(0%);
      }
    }
  }
  
  .header__section {
    position: absolute;
    z-index: 99;
    width: 100%;
    height: 80px;
  
    display: flex;
    margin: auto;
    align-items: center;
    justify-content: space-around;
  
    .header {
      margin-top: 3px;
      display: flex;
      align-items: center;
      justify-content: space-between;
      height: 80px;
      border-radius: 30px;
      background: rgba(105, 2, 126, 0.145);
      backdrop-filter: blur(4px);
      border: 1px solid #ffffff51;
    }
  
    .header-title {
      font-size: 1.3rem;
      cursor: pointer;
      color: #fff;
      font-weight: 200;
      letter-spacing: 0.01em;
      margin: 0;
  
      span {
        color: rgba(166, 0, 255, 0.635);
        font-weight: 400;
        padding-left: 1px;
      }
    }
  
    .header-links {
      display: flex;
      align-items: center;
      justify-content: center;
  
      a {
        padding: 8px 15px;
        margin: auto 30px;
        color: #fff;
        text-decoration: none;
        border-radius: 12px;
        border: 1px solid rgba(255, 255, 255, 0.1);
        transition: 0.3s ease-in-out;
      }
      :hover {
        background: rgba(241, 179, 255, 0.51);
        border: 1px solid rgba(255, 255, 255, 0.45);
      }
    }
  
    .bar-links {
      display: none;
      height: 28px;
      width: 30px;
      position: absolute;
      right: 10px;
      overflow: hidden;
      cursor: pointer;
      margin-left: 100px;
      .bar {
        border-radius: 5px;
        height: 6px;
        width: 100%;
        background-color: #fff;
        position: absolute;
        transition: 0.5s;
        &:nth-child(1) {
          top: 0%;
          transform: scaleY(0.9);
        }
        &:nth-child(2) {
          top: 50%;
          transform: scaleX(0.8) scaleY(0.9) translateY(-50%);
  
          transform-origin: right;
        }
        &:nth-child(3) {
          top: 100%;
          transform: scaleX(1) scaleY(0.9) translateY(-100%);
          transform-origin: right;
        }
      }
    }
    & .active {
      .bar {
        transform-origin: center;
        background-color: var(--primary-color) !important;
        top: 50%;
        right: 2px;
        &:nth-child(1) {
          transform: scale(0.6) rotate(45deg) translateY(100%) translateX(25%);
        }
        &:nth-child(2) {
          transform: scale(0);
        }
        &:nth-child(3) {
          transform: scale(0.6) rotate(-45deg) translateY(-710%) translateX(70%);
        }
      }
    }
  
    @media only screen and (max-width: 1024px) {
      .bar-links {
        display: block;
      }
  
      .black-everything {
        position: absolute;
        background: #0000008b;
        height: 101vh;
        top: -3px;
        left: 0;
        right: 0;
        transition: 0.5s;
        display: none;
      }
      .active__black {
        display: flex;
      }
  
      .header-links {
        display: none;
        transition: 0.5s;
        width: 100%;
        height: 80vh;
        background: rgba(12, 12, 12, 0.741);
        backdrop-filter: blur(20px);
        position: absolute;
        top: 110%;
        left: -0em;
        flex-direction: column;
        border-radius: 50px;
        overflow: hidden;
        animation: transition 0.3s ease-in-out;
        border: 1px solid #ffffff51;
        box-shadow: 1px 0px 44px -12px rgba(255, 255, 255, 0.6);
        -webkit-box-shadow: 1px 0px 44px -12px rgba(255, 255, 255, 0.6);
        -moz-box-shadow: 1px 0px 44px -12px rgba(255, 255, 255, 0.6);
        a {
          color: #fff;
          width: 10em;
          display: flex;
          align-items: center;
          justify-content: center;
          margin: 30px 0;
        }
      }
  
      @keyframes transition {
        from {
          transform: translateY(100%);
        }
        to {
          transform: translateY(0%);
        }
      }
  
      .active__header-links {
        transition: 0.3s;
        display: flex;
        transform: translateY(0%);
      }
    }
  
    @media only screen and (max-width: 425px) {
      .header-title {
        font-size: 1.1rem;
      }
    }
    @media only screen and (max-width: 307px) {
      .header-title {
        font-size: 1rem;
        span {
          display: none;
        }
      }
    }
  }
  