.contact-section {
  overflow: hidden;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100vh;
  background: rgb(15, 1, 73);
  background: linear-gradient(
    360deg,
    rgba(15, 1, 73, 1) 2%,
    rgba(40, 0, 66, 1) 100%
  );
  color: #fff;

  .container-contact {
    border-radius: 10px;
    padding: 10px;
    box-shadow: 0 0 10px rgba(110, 8, 182, 0.628),
      0 0 30px rgba(83, 1, 99, 0.685), 0 0 50px rgba(58, 0, 78, 0.966);
    background: rgba(242, 5, 171, 0.045);
    border-radius: 16px;
    backdrop-filter: blur(20px);
    -webkit-backdrop-filter: blur(20px);

    border: 1px solid rgba(242, 5, 171, 0.32);

    .form__line {
      margin-bottom: 20px;
      display: flex;
      flex-direction: column;
      label {
        padding-bottom: 5px;
      }
      input,
      textarea {
        border-radius: 10px;
        border: 1px solid transparent;
        outline: none;
        padding: 3px 9px;
        &:focus {
          color: #9500ff;
          border: 1px solid #ff0077;
        }
      }
    }

    .submit__btn {
      color: #fff;
      border: 1px solid rgba(255, 255, 255, 0.2);
      transition: 0.3s ease-in-out;

      &:hover {
        background: rgb(84, 1, 99);
        border: 1px solid rgba(255, 255, 255, 0.6);
      }
    }
  }
}
