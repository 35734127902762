.hero-section {
  overflow: hidden;
  z-index: 9;
  bottom: 0;
  width: 100%;
  height: 100vh;
  background: url("https://i.pinimg.com/originals/05/10/f4/0510f4e596283c0d961e4338565a93f3.gif");
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;

  .container-hero {
    position: relative;
    top: 10%;
    left: 0;

    .hero-info {
      position: absolute;
      top: 250px;
      width: 50%;
      color: #fff;
      z-index: 2;
      h5 {
        width: 100%;
        margin-bottom: 80px;
        font-size: 2.2rem;
      }
      p {
        line-height: 23px;
      }

      button {
        margin-top: 80px;
        padding: 8px 30px;
        color: #fff;
        border: 1px solid rgba(255, 255, 255, 0.327);
      }
      button:hover {
        background: #4f023ec4;
      }
    }

    .spline-section {
      position: absolute;
      right: 0;
      top: 0px;
      margin: 0;
      transform: scale(0.9) translateX(250px);
      right: 0;
    }
  }

  .soc__media {
    position: absolute;
    z-index: -1;
    top: -20px;
    left: -10px;
    width: 4px;
    height: 150%;
    background: rgb(143, 1, 130);
    background: radial-gradient(
      circle,
      rgba(143, 1, 130, 1) 8%,
      rgba(135, 6, 219, 1) 100%
    );
    border-radius: 40%;
    animation: comein 4s ease-in-out;

    @keyframes comein {
      from {
        transform: translateX(500px);
      }
      to {
        transform: translateX(0px);
      }
    }

    .soc__instagram {
      position: absolute;
      bottom: 0px;
      left: 5px;
    }
    .soc__github {
      position: absolute;
      bottom: 40px;
      left: 5px;
    }
  }

  @media only screen and (max-width: 2500px) {
    .hero-info {
      position: absolute;
      top: 250px !important;
    }
    .spline-section {
      position: absolute;
      right: 0px;
      top: -100px !important;
      transform: scale(0.8) translateX(450px) !important;
    }
  }

  @media only screen and (max-width: 1280px) {
    .hero-info {
      top: 100px !important;
    }
  }

  @media only screen and (max-width: 1098px) {
    .hero-info {
      top: 100px !important;
      h5 {
        margin-bottom: 15px !important;
      }

      p {
        font-size: 0.76rem !important;
        line-height: 21px !important;
      }

      button {
        margin-top: 10px !important;
      }
    }
    .spline-section {
      position: absolute;
      right: 0px;
      top: -100px !important;
      transform: scale(0.7) translateX(450px) translateY(-150px) !important;
    }
  }

  @media only screen and (max-width: 1024px) {
    .hero-info {
      position: absolute;
      top: 150px !important;
    }
    .spline-section {
      position: absolute;
      right: 0px;
      top: -150px !important;
      transform: scale(0.8) translateX(450px) !important;
    }
  }

  @media only screen and (max-width: 768px) {
    .hero-info {
      top: 150px !important;
      h5 {
        font-size: 1.9rem !important;
        margin-bottom: 10px !important;
      }
      p {
        font-size: 0.7rem !important;
        line-height: 20px !important;
      }
      button {
        margin-top: 10px !important;
      }
    }
    .spline-section {
      position: absolute;
      right: 0px;
      top: -200px;
      transform: scale(0.8) translateX(450px) !important;
    }
  }
  @media only screen and (max-width: 560px) {
    .spline-section {
      position: absolute;
      right: 0px;
      transform: scale(0.5) translateX(1000px) !important;
    }
  }

  @media only screen and (max-width: 425px) {
    .container-hero {
      top: -10px;
      .hero-info {
        width: 90%;
        h5 {
          margin-bottom: 30px !important;
          font-size: 1.6rem !important;
        }
        p {
          font-size: 0.7rem;
          line-height: 23px;
        }

        button {
          margin-top: 30px !important;
          padding: 8px 30px;
          color: #fff;
          border: 1px solid rgba(255, 255, 255, 0.327);
        }
        button:hover {
          background: #4f023e;
        }
      }
      .spline-section {
        display: none;
      }
    }
  }
}
